import DocumentsActionsCell from './DocumentsActionsCell';

export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Name',
        key: 'DisplayName',
        minWidth: 80,
    },
    {
        title: 'Attachment',
        width: 80,
        align: 'center',
        render: (h, params) => {
            return h('div', [
                h(DocumentsActionsCell, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
    {
        title: 'Document Type',
        key: 'description',
        minWidth: 80,
    },
    {
        title: 'Uploaded On',
        key: 'UploadedOn',
        minWidth: 80,
    },    
    {
        title: 'Notes',
        key: 'Notes',
        minWidth: 80,
    }
]
